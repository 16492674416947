export default {
    AUTH: "auth",
    OTHER: "other",

    MANAGEMENT: "management",
    SORTATION: "sortation",
    FULFILLMENT: "fulfillment",
    K_PARCEL: "kparcel",
    JIRA: "jira",

    SETTING: "setting",
    SETTING_FILTER_PREFERENCE: "setting_filter_preference",

    CRM: "crm",
    ERP: "erp",
    ERP_DEV: "erp-dev",
}
