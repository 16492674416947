import store from '@/store';
import dayjs from "dayjs"
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

export const YEAR_FORMAT = "YYYY";
export const YEAR_DATE_FORMAT = "YYYY-MM";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

export const getZoned = (dateTime) => {
    return dayjs(dateTime).tz(store.state.app.displayTimezone);
}

export const getZonedCurrentMonthStart = () => {
    return getZoned().startOf('month');
}

export const getZonedCurrentMonthEnd = () => {
    return getZoned().endOf('month');
}

export const getZonedPreviousMonthStart = () => {
    return getZonedCurrentMonthStart().subtract(1, 'month');
}

export const getZonedPreviousMonthEnd = () => {
    return getZonedCurrentMonthEnd().subtract(1, 'month');
}


export const getZonedPreviousYearStart = () => {
    return getZonedCurrentYearStart().subtract(1, 'year');
}

export const getZonedPreviousYearEnd = () => {
    return getZonedCurrentYearEnd().subtract(1, 'year');
}

export const getZonedCurrentYearStart = () => {
    return getZoned().startOf('year');
}

export const getZonedCurrentYearEnd = () => {
    return getZoned().endOf('year');
}

export const getZonedPreviousYearTodayEnd = () => {
    return getZonedTodayEnd().subtract(1, 'year');
}

export const getZonedTodayEnd = () => {
    return getZoned().endOf('day');
}



export const getLocal = () => {
    return dayjs().local();
}

export const getLocalDateTimeString = () => {
    return getLocal().format(DATE_TIME_FORMAT);
}



export const getZonedDatesFromFullDates = (dates) => {
    return dates.map(date => dayjs(date).tz(store.state.app.displayTimezone).get("date"));
}

export const getZonedYearMonthFromFullDate = (date) => {
    return dayjs(date).tz(store.state.app.displayTimezone).format(YEAR_DATE_FORMAT);
}

export const getZonedYearFromFullDate = (date) => {
    return dayjs(date).tz(store.state.app.displayTimezone).format(YEAR_FORMAT);
}