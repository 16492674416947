import Permissions from '@configs/permissions';

export default [
  {
    path: '/settings/accounts',
    name: 'setting-account',
    component: () => import('@/views/settings/accounts/AccountList.vue'),
    meta: {
      resource: Permissions.SETTING,
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Accounts',
          active: true,
        },
      ],
    }
  },
  {
    path: '/settings/accounts/create',
    name: 'setting-create-account',
    component: () => import('@/views/settings/accounts/CreateAccount.vue'),
    meta: {
      resource: Permissions.SETTING,
      pageTitle: 'Create Account',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Account',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    }
  },
  {
    path: '/settings/accounts/:userId',
    name: 'setting-account-detail',
    component: () => import('@/views/settings/accounts/AccountDetail.vue'),
    meta: {
      resource: Permissions.SETTING,
      pageTitle: 'Account Detail',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Accounts',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    }
  },
  {
    path: '/settings/login-logs',
    name: 'setting-login-logs',
    component: () => import('@/views/settings/accounts/LoginLogs.vue'),
    meta: {
      resource: Permissions.SETTING,
      pageTitle: 'Login Logs',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Login Logs',
          active: true,
        },
      ],
    }
  },
  {
    path: '/settings/accounts/my/preferences',
    name: 'setting-filter-preference',
    component: () => import('@/views/settings/accounts/FilterPreference.vue'),
    meta: {
      resource: Permissions.SETTING_FILTER_PREFERENCE,
      pageTitle: 'My Preference',
      breadcrumb: [
        {
          text: 'My Preference',
          active: true
        },
      ],
    }
  },
  {
    path: '/settings/accounts/my/security',
    name: 'setting-my-security',
    component: () => import('@/views/settings/accounts/Security.vue'),
    meta: {
      resource: Permissions.SETTING_FILTER_PREFERENCE,
      pageTitle: 'Security',
      breadcrumb: [
        {
          text: 'Security',
          active: true
        },
      ],
    }
  },
]
