import Vue from 'vue'
import axios from 'axios'
import ErrorCode from '@configs/response-error-code'
import router from '@/router'
import { cleanAccessToken, getAccessToken } from '@/utils/account-localstorage'
import store from "@/store";

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT_URL,
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const axiosBaseUrl = axiosIns.defaults.baseURL;

const generalResponseHandler = response => {
  try {
    if (response == null) {
      store.commit("app/SHOW_ERROR_DIALOG", {
        isOpen: true,
        message: "Request Timeout",
      });

      return Promise.reject("Request Timeout");
    }

    const { data } = response

    if (data.success) {
      return data.data
    }

    if (!data.error) {
      return data.data;
    }

    switch (data.error.code) {
      case ErrorCode.UNAUTHORIZED:
        cleanAccessToken()
        router.push('/login')
        break
      case ErrorCode.FORBIDDEN:
        router.push('/not-authorized')
        break
      default:
        return Promise.reject({
          message: data.error.message ?? data.message,
          detail: data.error.detail ?? data.detail
        })
    }
  } catch (e) {
    console.log('axios', e);
  }
}

// Add a response interceptor
axiosIns.interceptors.response.use(response => generalResponseHandler(response), error => generalResponseHandler(error.response))

Vue.prototype.$http = axiosIns

// --- export ---

export const FetchMethod = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
}

/**
 * @deprecated use axios directly
 */
export const fetcher = (postfixUrl, method, data, options = {}) => {
  if (!postfixUrl) return;

  const axiosOptions = Object.assign({ headers: { Authorization: `Bearer ${getAccessToken()}` } }, options)

  if (method === FetchMethod.GET) {
    // axios auto remove null query params
    return axiosIns.get(postfixUrl, { ...axiosOptions, params: data })
  } else if (method === FetchMethod.PUT) {
    return axiosIns.put(postfixUrl, data, axiosOptions)
  } else if (method === FetchMethod.PATCH) {
    return axiosIns.patch(postfixUrl, data, axiosOptions)
  } else if (method === FetchMethod.DELETE) {
    return axiosIns.delete(postfixUrl, axiosOptions)
  } else {
    return axiosIns.post(postfixUrl, data, axiosOptions)
  }
}
