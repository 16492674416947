import Permissions from '@configs/permissions';

export default [
  {
    path: '/erp/organisation-profile',
    name: 'erp-organisation-profile',
    component: () => import('@/views/erp/OrganizationProfile.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Organisation Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/organisation-profile/create',
    name: 'erp-organisation-profile-create',
    component: () => import('@/views/erp/OrganizationProfileForm.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Organisation Profile',
          to: './'
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },

  {
    path: '/erp/organisation-profile/:id/edit',
    name: 'erp-organisation-profile-edit',
    component: () => import('@/views/erp/OrganizationProfileForm.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Organisation Profile',
          to: '..'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/company-profile/:id',
    name: 'erp-company-profile',
    component: () => import('@/views/erp/company-profile/Profile.vue'),
    meta: {
      resource: Permissions.ERP,
    },
  },

  {
    path: '/erp/vendor-profile',
    name: 'erp-vendor-profile',
    component: () => import('@/views/erp/VendorProfile.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Vendor Profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/vendor-profile/create',
    name: 'erp-vendor-profile-create',
    component: () => import('@/views/erp/VendorProfileForm.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Vendor Profile',
          to: './'
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },

  {
    path: '/erp/vendor-profile/:id/edit',
    name: 'erp-vendor-profile-edit',
    component: () => import('@/views/erp/VendorProfileForm.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Vendor Profile',
          to: '..'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/vendor-company-profile/:id',
    name: 'erp-vendor-company-profile',
    component: () => import('@/views/erp/vendor-company-profile/Profile.vue'),
    meta: {
      resource: Permissions.ERP,
    },
  },
  {
    path: '/erp/invoice',
    name: 'erp-invoice',
    component: () => import('@/views/erp/Invoice.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/credit-note',
    name: 'erp-credit-note',
    component: () => import('@/views/erp/CreditNote.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Credit Note',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/company-profile/:company_id/invoice/:id/edit',
    name: 'erp-invoice-edit',
    component: () => import('@/views/erp/InvoiceForm.vue'),
    props: {action: 'edit'},
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Company Profile',
          to: '../..',
        },
        {
          text: 'Invoice',
          to: '../..#invoice',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/company-profile/:company_id/invoice/:id/view',
    name: 'erp-invoice-view',
    component: () => import('@/views/erp/InvoiceForm.vue'),
    props: {action: 'view'},
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Company Profile',
          to: '../..',
        },
        {
          text: 'Invoice',
          to: '../..#invoice',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/bank-statement',
    name: 'erp-bank-statement',
    component: () => import('@/views/erp/BankStatement.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Bank Statement',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/charge-code',
    name: 'erp-charge-code',
    component: () => import('@/views/erp/ChargeCode.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Charge Code',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/api-actual-receivable',
    name: 'erp-api-actual-receiveable',
    component: () => import('@/views/erp/IncomingActualReceivable.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'API Actual Receivable',
          active: true,
        },
      ],
    },
  },
  {
    path: '/erp/api-actual-payable',
    name: 'erp-api-actual-payable',
    component: () => import('@/views/erp/IncomingActualPayable.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'API Actual Payable',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/api-actual-payable-summary',
    name: 'erp-api-actual-payable-summary',
    component: () => import('@/views/erp/IncomingActualPayableSummary.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'API Actual Payable Summary',
          active: true,
        },
      ],
    }
  },

  {
    path: '/erp/currency-profile',
    name: 'erp-currency-profile',
    component: () => import('@/views/erp/CurrencyProfile.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Currency Profile',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/warehouse-code-mapping',
    name: 'erp-warehouse-code-mapping',
    component: () => import('@/views/erp/WarehouseCodeMapping.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Warehouse Code Mapping',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/email-template',
    name: 'erp-email-template',
    component: () => import('@/views/erp/EmailTemplate.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Email Template',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/accrual',
    name: 'erp-accrual',
    component: () => import('@/views/erp/Accrual.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Accrual',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/users',
    name: 'erp-user',
    component: () => import('@/views/erp/User.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Users and Teams',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/roles',
    name: 'erp-role',
    component: () => import('@/views/erp/Role.vue'),
    meta: {
      resource: Permissions.ERP,
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Roles',
          active: true,
        },
      ],
    }
  },
  {
    path: '/erp/preferences',
    name: 'erp-preference',
    component: () => import('@/views/erp/Preference.vue'),
    meta: {
      resource: Permissions.ERP,
      pageTitle: 'Preferences',
      breadcrumb: [
        {
          text: 'ERP',
        },
        {
          text: 'Preferences',
          active: true,
        },
      ],
    }
  }
]
