import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import sortation from './sortation'
import fulfillment from './fulfillment'
import kParcel from './k-parcel';
import management from './management';
import crm from './crm';

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sortation,
    fulfillment,
    kParcel,
    management,
    crm,
    
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
