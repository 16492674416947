import Permissions from '@configs/permissions';

export default [
    {
        path: '/jira/tasks',
        name: 'jira-tasks',
        component: () => import('@/views/jira/JiraTasks.vue'),
        meta: {
          resource: Permissions.JIRA,
          pageTitle: 'Tasks',
          breadcrumb: [
            {
              text: 'Jira',
            },
            {
              text: 'Tasks',
              active: true,
            },
          ],
        }
      },
      {
        path: '/jira/realtime',
        name: 'jira-realtime',
        component: () => import('@/views/jira/Realtime.vue'),
        meta: {
          resource: Permissions.JIRA,
          pageTitle: 'Realtime',
          breadcrumb: [
            {
              text: 'Jira',
            },
            {
              text: 'Realtime',
              active: true,
            },          
          ],
          // layout: 'full',
        },
      },
      {
        path: '/jira/dashboard',
        name: 'jira-dashboard',
        component: () => import('@/views/jira/Dashboard.vue'),
        meta: {
          resource: Permissions.JIRA,
          pageTitle: 'Dashboard',
          breadcrumb: [
            {
              text: 'Jira',
            },
            {
              text: 'Dashboard',
              active: true,
            },          
          ],
          // layout: 'full',
        },
      },
]
