import { managementFilter, RANGE_CURRENT_MONTH, RANGE_YEAR_TO_DATE, RANGE_FULL_YEAR, RANGE_CUSTOM_YEAR, RANGE_CUSTOM_MONTH, X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter"; //Locations, Clients, 
import { getZonedCurrentYearStart, getZonedCurrentMonthStart, getZonedCurrentYearEnd } from "@/utils/date-time-helper";
import { RANGE_CUSTOM_RANGE_YEAR } from "@/configs/filter";
import {
    getFilterPreference,
} from "@/utils/account-localstorage";


export default {
    namespaced: true,
    state: {
        filter: {
            // must be '%'
            companyCode: ['KEC_ALL'],
            range: getFilterPreference().preferManagementRange, //RANGE_YEAR_TO_DATE,
            customYear: null,
            customMonth: null,
            customStartYear: null,
            customEndYear: null,
        },
        tooltipsState: {
            isOpen: false,
            data: [],
        }
    },
    getters: {
        GET_QUERY_PARAMS: (state) => {
            return managementFilter(
                state.filter.range, 
                state.filter.companyCode, 
                state.filter.customYear, 
                state.filter.customMonth,

                state.filter.customStartYear,
                state.filter.customEndYear
            );
        }
    },
    mutations: {
        UPDATE_FILTER_COMPANY_CODE(state, val) {
            state.filter.companyCode = val;
        },
        UPDATE_FILTER_RANGE(state, val) {
            state.filter.range = val;

            if (val == RANGE_CUSTOM_YEAR) {
                state.filter.customYear = getZonedCurrentYearStart().toDate();
            } else if (val == RANGE_CUSTOM_MONTH) {
                state.filter.customMonth = getZonedCurrentMonthStart().toDate();
            } else if (val == RANGE_CUSTOM_RANGE_YEAR) {
                state.filter.customStartYear = getZonedCurrentYearEnd().subtract(4, 'year').toDate();
                state.filter.customEndYear = getZonedCurrentYearEnd().toDate();
            }
        },

        UPDATE_FILTER_CUSTOM_YEAR(state, val) {
            state.filter.customYear = val;
        },
        UPDATE_FILTER_CUSTOM_MONTH(state, val) {
            state.filter.customMonth = val;
        },

        UPDATE_FILTER_CUSTOM_START_YEAR(state, val) {
            state.filter.customStartYear = val;
        },
        UPDATE_FILTER_CUSTOM_END_YEAR(state, val) {
            state.filter.customEndYear = val;
        },

        UPDATE_TOOLTIPS_STATE(state, val) {
            state.tooltipsState = val;
        },
    },
    actions: {

    },
}
