import Permissions from '@configs/permissions';

export default [
  {
    path: '/management/p&l',
    name: 'management-p&l',
    component: () => import('@/views/management/ManagementPnL.vue'),
    meta: {
      resource: Permissions.MANAGEMENT,
      pageTitle: 'P&L',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'P&L',
          active: true,
        },
      ],
    }
  },
]
