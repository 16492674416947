
export const NUM_OF_CHART_OPTIONS = [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
];

export const SORTATION_CHART = [
    { name: "Revenue", value: "revenue" },
];

export const FULFILLMENT_CHART = [
    { name: "Revenue", value: "RevenueLineBar", singlePage: false },
    { name: "GP", value: "GPLineBar", singlePage: false },
    { name: "FTE & PTE Headcount", value: "FTEAndPTEHeadCount", singlePage: false },
    { name: "FTE & PTE Cost", value: "FTEAndPTECost", singlePage: false },
    { name: "Inbound Volume", value: "InboundVolume", singlePage: false },
    { name: "Outbound Volume", value: "OutboundVolume", singlePage: false },
    { name: "Inventory", value: "InventoryLineBar", singlePage: false },
    { name: "Picking Accuracy", value: "PickingAccuracy", singlePage: false },
    { name: "Cycle Count", value: "CycleCount", singlePage: false },
    { name: "Relocation", value: "Relocation", singlePage: false },
    { name: "Shortage", value: "Shortage", singlePage: false },
    { name: "Damage", value: "Damage", singlePage: false },
    // { name: "Sq.ft Usage", value: "StorageSqFt", singlePage: true },
    { name: "Warehouse Utilization", value: "WarehouseUsage", singlePage: true },
    { name: "Revenue Detail", value: "RevenuePie", singlePage: false },
    { name: "GP Detail", value: "GPPie", singlePage: false },
    { name: "Inventory Detail", value: "InventoryPie", singlePage: false },
    { name: "VAS", value: "VASPie", singlePage: false },
];