import Permissions from '@configs/permissions';

export default [
  {
    path: '/k-parcel/operations',
    name: 'k-parcel-operations',
    component: () => import('@/views/k-parcel/KParcelOperations.vue'),
    meta: {
      resource: Permissions.K_PARCEL,
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'K-Parcel',
        },
        {
          text: 'Operations',
          active: true,
        },
      ],
    }
  },
  {
    path: '/k-parcel/performances',
    name: 'k-parcel-performances',
    component: () => import('@/views/k-parcel/KParcelPerformance.vue'),
    meta: {
      resource: Permissions.K_PARCEL,
      pageTitle: 'Performances',
      breadcrumb: [
        {
          text: 'K-Parcel',
        },
        {
          text: 'Performances',
          active: true,
        },
      ],
    }
  },
  {
    path: '/k-parcel/daily-volumes',
    name: 'k-parcel-daily-volumes',
    component: () => import('@/views/k-parcel/KParcelDailyVolumes.vue'),
    meta: {
      resource: Permissions.K_PARCEL,
      pageTitle: 'Daily Volumes',
      breadcrumb: [
        {
          text: 'K-Parcel',
        },
        {
          text: 'Daily Volumes',
          active: true,
        },
      ],
    }
  },
]
