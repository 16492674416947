import { FULFILLMENT_CHART } from "@/configs/charts";
import { dateCentreClientFilter, RANGE_LAST_MONTH, RANGE_CURRENT_MONTH, RANGE_YEAR_TO_DATE, RANGE_FULL_YEAR, RANGE_CUSTOM_YEAR, RANGE_CUSTOM_MONTH, RANGE_CUSTOM_RANGE_YEAR, X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter"; //Locations, Clients, 
import { getZonedCurrentYearStart, getZonedCurrentMonthStart, getZonedCurrentYearEnd } from "@/utils/date-time-helper";
import {
    getFilterPreference,
} from "@/utils/account-localstorage";

export default {
    namespaced: true,
    state: {
        tool: {
            numOfChartsPerRow: 2,
            displayCharts: FULFILLMENT_CHART,
        },
        filter: {
            // must be '%'
            centreId: ['KEC_ALL'],
            clientId: ['KEC_ALL'],
            range: getFilterPreference().preferFulfillmentRange, //RANGE_CURRENT_MONTH,
            customYear: null,
            customMonth: null,
            customStartYear: null,
            customEndYear: null,
        },
    },
    getters: {
        GET_QUERY_PARAMS: (state) => {
            return dateCentreClientFilter(
                state.filter.range, 
                state.filter.centreId, 
                state.filter.clientId, 
                state.filter.customYear, 
                state.filter.customMonth,

                state.filter.customStartYear,
                state.filter.customEndYear
            );
        },
        GET_QUERY_PARAMS_WITH_LAST_MONTH: (state) => {
            let range;
            if(state.filter.range == RANGE_CURRENT_MONTH) {
                // replace to last month
                range = RANGE_LAST_MONTH;
            } else {
                range = state.filter.range;
            }

            return dateCentreClientFilter(
                range, 
                state.filter.centreId, 
                state.filter.clientId, 
                state.filter.customYear, 
                state.filter.customMonth,

                state.filter.customStartYear,
                state.filter.customEndYear
            );
        }
    },
    mutations: {
        UPDATE_NUM_OF_CHARTS_PER_ROW(state, val) {
            state.tool.numOfChartsPerRow = val;
        },
        UPDATE_DISPLAY_CHARTS(state, val) {
            state.tool.displayCharts = val;
        },

        UPDATE_FILTER_CENTRE(state, val) {
            state.filter.centreId = val;
        },
        UPDATE_FILTER_CLIENT(state, val) {
            state.filter.clientId = val;
        },
        UPDATE_FILTER_RANGE(state, val) {
            state.filter.range = val;

            if (val == RANGE_CUSTOM_YEAR) {
                state.filter.customYear = getZonedCurrentYearStart().toDate();
            } else if (val == RANGE_CUSTOM_MONTH) {
                state.filter.customMonth = getZonedCurrentMonthStart().toDate();
            } else if (val == RANGE_CUSTOM_RANGE_YEAR) {
                state.filter.customStartYear = getZonedCurrentYearEnd().subtract(4, 'year').toDate();
                state.filter.customEndYear = getZonedCurrentYearEnd().toDate();
            }
        },

        UPDATE_FILTER_CUSTOM_YEAR(state, val) {
            state.filter.customYear = val;
        },
        UPDATE_FILTER_CUSTOM_MONTH(state, val) {
            state.filter.customMonth = val;
        },

        UPDATE_FILTER_CUSTOM_START_YEAR(state, val) {
            state.filter.customStartYear = val;
        },
        UPDATE_FILTER_CUSTOM_END_YEAR(state, val) {
            state.filter.customEndYear = val;
        },
    },
    actions: {

    },
}
