import Permissions from '@configs/permissions';

export const initialAbility = [
  {
    action: 'manage',
    subject: Permissions.AUTH,
  },
  {
    action: 'manage',
    subject: Permissions.OTHER
  },
  {
    action: 'manage',
    subject: Permissions.SETTING_FILTER_PREFERENCE
  }
]

export const _ = undefined
