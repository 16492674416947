import Permissions from '@configs/permissions';

export default [
  {
    path: '/crm/deals',
    name: 'crm-deals',
    component: () => import('@/views/crm/Deal.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Deals',
          active: true,
        },
      ],
    }
  },
  {
    path: '/crm/pipelines',
    name: 'crm-pipeline',
    component: () => import('@/views/crm/Pipeline.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Pipelines',
          active: true,
        },
      ],
    }
  },
  {
    path: '/crm/organisations',
    name: 'crm-organisation',
    component: () => import('@/views/crm/Organization.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Organisations',
          active: true,
        },
      ],
    }
  },
  {
    path: '/crm/contacts',
    name: 'crm-contact',
    component: () => import('@/views/crm/Contact.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Contacts',
          active: true,
        },
      ],
    }
  },
  {
    path: '/crm/products',
    name: 'crm-product',
    component: () => import('@/views/crm/Product.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Products',
          active: true,
        },
      ],
    }
  },
  {
    path: '/crm/users',
    name: 'crm-user',
    component: () => import('@/views/crm/User.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Users and Teams',
          active: true,
        },
      ],
    }
  },
  {
    path: '/crm/preferences',
    name: 'crm-preference',
    component: () => import('@/views/crm/Preference.vue'),
    meta: {
      resource: Permissions.CRM,
      breadcrumb: [
        {
          text: 'CRM',
        },
        {
          text: 'Preferences',
          active: true,
        },
      ],
    }
  }
]
