import Permissions from '@configs/permissions';

export default [
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
            action: 'read',
        },
    },
    {
        path: '/under-maintenance',
        name: 'misc-under-maintenance',
        component: () => import('@/views/other/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
        },
    },
    {
        path: '/coming-soon',
        name: 'misc-coming-soon',
        component: () => import('@/views/other/ComingSoon.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
        },
    },
    {
        path: '/link-expired',
        name: 'other-link-expired',
        component: () => import('@/views/other/LinkExpired.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
        },
    },
]
