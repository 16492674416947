import { $themeBreakpoints } from '@themeConfig'
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import sources from "@configs/sources";

const getSourceFromRouteName = (name) => {
  if (["fulfillment-management", "fulfillment-operations", "fulfillment-accrual"].includes(name)) {
    return sources.TWMS;
  } else if (["k-parcel-operations", "k-parcel-performances"].includes(name)) {
    return sources.TOMS;
  }
}


export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,

    displayTimezone: "Asia/Hong_Kong",

    centre: {
      isLoading: false,
      isLoadFailed: false,
      list: [],
    },
    client: {
      isLoading: false,
      isLoadFailed: false,
      list: [],
    },
    company: {
      isLoading: false,
      isLoadFailed: false,
      list: [],
    },
    product: {
      isLoading: false,
      isLoadFailed: false,
      list: [],
    },

    dialogPieChart: {
      isOpen: false,
      apiUrl: null,
      title: null,
    },

    errorDialog: {
      isOpen: false,
      title: null,
      message: null,
    }
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },

    GET_COMPANY_STATE(state) {
      return {
        ...state.company,
        list: [
          // { value: null, text: "All" },
          ...state.company.list.map(company => { return { value: company.code, text: company.code } })
        ]
      }
    },
    GET_CENTRE_STATE(state) {
      return {
        ...state.centre,
        list: [
          // { value: null, text: "All" },
          ...state.centre.list.map(centre => { return { value: centre.centreId, text: centre.name } })
        ]
      }
    },
    GET_CLIENT_STATE(state) {
      return {
        ...state.client,
        list: [
          // { value: null, text: "Overall" },
          ...state.client.list.map(client => { return { value: client.clientId, text: client.companyName } })
        ]
      }
    },
    GET_PRODUCT_STATE(state) {
      return {
        ...state.product,
        list: [
          { value: null, text: "Overall" },
          ...state.product.list.map(product => { return { value: product.productId, text: product.productName } })
        ]
      }
    },

    
    GET_PRODUCT_STATE_WITHOUT_OVERALL(state) {
      return {
        ...state.product,
        list: state.product.list.map(product => { return { value: product.productId, text: product.productName } })
      }
    }
  },
  mutations: {
    SHOW_ERROR_DIALOG(state, val) {
      state.errorDialog = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },

    // self code
    UPDATE_COMPANY_STATE(state, val) {
      if (val.isLoading != null) {
        state.company.isLoading = val.isLoading;
      }

      if (val.isLoadFailed != null) {
        state.company.isLoadFailed = val.isLoadFailed;
      }

      if (val.list != null) {
        state.company.list = val.list;
      }
    },
    UPDATE_CENTRE_STATE(state, val) {
      if (val.isLoading != null) {
        state.centre.isLoading = val.isLoading;
      }

      if (val.isLoadFailed != null) {
        state.centre.isLoadFailed = val.isLoadFailed;
      }

      if (val.list != null) {
        state.centre.list = val.list;
      }
    },
    UPDATE_CLIENT_STATE(state, val) {
      if (val.isLoading != null) {
        state.client.isLoading = val.isLoading;
      }

      if (val.isLoadFailed != null) {
        state.client.isLoadFailed = val.isLoadFailed;
      }

      if (val.list != null) {
        state.client.list = val.list;
      }
    },
    UPDATE_PRODUCT_STATE(state, val) {
      if (val.isLoading != null) {
        state.product.isLoading = val.isLoading;
      }

      if (val.isLoadFailed != null) {
        state.product.isLoadFailed = val.isLoadFailed;
      }

      if (val.list != null) {
        state.product.list = val.list;
      }
    },
    UPDATE_DIALOG_PIE_CHART(state, val) {
      state.dialogPieChart = val;
    },
  },
  actions: {
    async FETCH_COMPANY_IF_NEED(context, data) {
      // if(context.state.product.list.length == 0 || context.state.product.isLoadFailed) {
      try {
        context.commit("UPDATE_COMPANY_STATE", { isLoading: true, isLoadFailed: false });
        const response = await fetcher(
          apis.listCompany,
          FetchMethod.GET,
          {
            source: getSourceFromRouteName(data.routeName)
          }
        );

        context.commit("UPDATE_COMPANY_STATE", { isLoading: false, isLoadFailed: false, list: response });
      } catch (e) {
        context.commit("UPDATE_COMPANY_STATE", { isLoading: false, isLoadFailed: true });
      }
      // }
    },
    async FETCH_PRODUCT_IF_NEED(context, data) {
      // if(context.state.product.list.length == 0 || context.state.product.isLoadFailed) {
      try {
        context.commit("UPDATE_PRODUCT_STATE", { isLoading: true, isLoadFailed: false });
        const response = await fetcher(
          apis.listProduct,
          FetchMethod.GET,
          {
            source: getSourceFromRouteName(data.routeName)
          }
        );

        context.commit("UPDATE_PRODUCT_STATE", { isLoading: false, isLoadFailed: false, list: response });
      } catch (e) {
        context.commit("UPDATE_PRODUCT_STATE", { isLoading: false, isLoadFailed: true });
      }
      // }
    },
    async FETCH_CENTERS_IF_NEED(context, data) {
      // if(context.state.centre.list.length == 0 || context.state.centre.isLoadFailed) {
      try {
        context.commit("UPDATE_CENTRE_STATE", { isLoading: true, isLoadFailed: false });
        const response = await fetcher(
          apis.listCentre,
          FetchMethod.GET,
          {
            source: getSourceFromRouteName(data.routeName)
          }
        );

        context.commit("UPDATE_CENTRE_STATE", { isLoading: false, isLoadFailed: false, list: response });
      } catch (e) {
        context.commit("UPDATE_CENTRE_STATE", { isLoading: false, isLoadFailed: true });
      }
      // }
    },
    async FETCH_CLIENT_IF_NEED(context, data) {
      // if(context.state.client.list.length == 0 || context.state.client.isLoadFailed) {
      try {
        context.commit("UPDATE_CLIENT_STATE", { isLoading: true, isLoadFailed: false });

        const response = await fetcher(
          apis.listClient,
          FetchMethod.GET,
          {
            source: getSourceFromRouteName(data.routeName)
          }
        );

        context.commit("UPDATE_CLIENT_STATE", { isLoading: false, isLoadFailed: false, list: response });
      } catch (e) {
        context.commit("UPDATE_CLIENT_STATE", { isLoading: false, isLoadFailed: true });
      }
      // }
    },
  },
}
