import {
    getZonedPreviousMonthStart,
    getZonedPreviousMonthEnd,
    getZonedCurrentMonthStart,
    getZonedCurrentMonthEnd,

    getZonedPreviousYearStart,
    getZonedPreviousYearEnd,
    getZonedCurrentYearStart,
    getZonedCurrentYearEnd,

    getZonedPreviousYearTodayEnd,
    getZonedTodayEnd,
} from '@/utils/date-time-helper';
import _ from 'lodash';
import dayjs from 'dayjs';

export const RANGE_LAST_MONTH = "last_month";
export const RANGE_CURRENT_MONTH = "current_month";
export const RANGE_YEAR_TO_DATE = "year_to_date";
export const RANGE_FULL_YEAR = "full_year";
export const RANGE_RANGE_YEARS = "range_year";
export const RANGE_CUSTOM_YEAR = "custom_year";
export const RANGE_CUSTOM_MONTH = "custom_month";
export const RANGE_CUSTOM_RANGE_YEAR = "custom_range_year";

export const X_AXIS_MODE_DATE = "date";
export const X_AXIS_MODE_MONTH = "month";
export const X_AXIS_MODE_YEAR = "year";

export const ManagementRanges = [
    {
        text: "Default Options",
        options: [
            { value: RANGE_YEAR_TO_DATE, text: "Year to Date" },
            { value: RANGE_FULL_YEAR, text: "Full Year" },
            { value: RANGE_RANGE_YEARS, text: "Last 5 years" },
        ]
    },
    {
        text: "Custom Options",
        options: [
            { value: RANGE_CUSTOM_YEAR, text: "Custom Display By Month" },
            { value: RANGE_CUSTOM_RANGE_YEAR, text: "Custom Range Years" },
        ]
    },
];

export const Ranges = [
    {
        text: "Default Options",
        options: [
            { value: RANGE_CURRENT_MONTH, text: "Current Month" },
            { value: RANGE_YEAR_TO_DATE, text: "Year to Date" },
            { value: RANGE_FULL_YEAR, text: "Full Year" },
            { value: RANGE_RANGE_YEARS, text: "Last 5 years" },
        ]
    },
    {
        text: "Custom Options",
        options: [
            { value: RANGE_CUSTOM_MONTH, text: "Custom Display By Day" },
            { value: RANGE_CUSTOM_YEAR, text: "Custom Display By Month" },
            { value: RANGE_CUSTOM_RANGE_YEAR, text: "Custom Range Years" },
        ]
    },
];

const rangeFilter = (range, customYear, customMonth, customStartYear, customEndYear) => {
    let currentStartDate;
    let currentEndDate;

    let previousStartDate;
    let previousEndDate;

    let xAxisMode;

    if (range == RANGE_LAST_MONTH) {
        currentStartDate = getZonedCurrentMonthStart().subtract(1, 'month').toISOString()
        currentEndDate = getZonedCurrentMonthEnd().subtract(1, 'month')//.toISOString()

        previousStartDate = getZonedPreviousMonthStart().subtract(1, 'month').toISOString()
        previousEndDate = getZonedPreviousMonthEnd().subtract(1, 'month').toISOString()

        xAxisMode = X_AXIS_MODE_DATE;
    } else if (range == RANGE_CURRENT_MONTH) {
        currentStartDate = getZonedCurrentMonthStart().toISOString()
        currentEndDate = getZonedCurrentMonthEnd()//.toISOString()

        previousStartDate = getZonedPreviousMonthStart().toISOString()
        previousEndDate = getZonedPreviousMonthEnd().toISOString()

        xAxisMode = X_AXIS_MODE_DATE;
    } else if (range == RANGE_YEAR_TO_DATE) {
        currentStartDate = getZonedCurrentYearStart().toISOString()
        currentEndDate = getZonedTodayEnd()//.toISOString()

        previousStartDate = getZonedPreviousYearStart().toISOString()
        previousEndDate = getZonedPreviousYearTodayEnd().toISOString()

        xAxisMode = X_AXIS_MODE_MONTH;
    } else if(range == RANGE_FULL_YEAR) {
        currentStartDate = getZonedCurrentYearStart().toISOString()
        currentEndDate = getZonedCurrentYearEnd()//.toISOString()

        previousStartDate = getZonedPreviousYearStart().toISOString()
        previousEndDate = getZonedPreviousYearEnd().toISOString()

        xAxisMode = X_AXIS_MODE_MONTH;
    } else if(range == RANGE_RANGE_YEARS) {
        currentStartDate = getZonedCurrentYearStart().subtract(4, 'year')
        currentEndDate = getZonedCurrentYearEnd()

        previousStartDate = currentStartDate.subtract(4, 'year').toISOString();
        previousEndDate = currentEndDate.subtract(4, 'year').toISOString();

        currentStartDate = currentStartDate.toISOString();

        xAxisMode = X_AXIS_MODE_YEAR;
    } else if(range == RANGE_CUSTOM_YEAR) {
        let dCurrentStartDate = dayjs(customYear);
        let dPreviousStartDate = dCurrentStartDate.subtract(1, 'year');

        currentStartDate = dCurrentStartDate.toISOString();
        currentEndDate = dCurrentStartDate.endOf('year');

        previousStartDate = dPreviousStartDate.toISOString();
        previousEndDate = dPreviousStartDate.endOf('year').toISOString();

        xAxisMode = X_AXIS_MODE_MONTH;
    } else if(range == RANGE_CUSTOM_MONTH) {
        let dCurrentStartDate = dayjs(customMonth);
        let dPreviousStartDate = dCurrentStartDate.subtract(1, 'month');

        currentStartDate = dCurrentStartDate.toISOString();
        currentEndDate = dCurrentStartDate.endOf('month');

        previousStartDate = dPreviousStartDate.toISOString();
        previousEndDate = dPreviousStartDate.endOf('month').toISOString();

        xAxisMode = X_AXIS_MODE_DATE;
    } else if(range == RANGE_CUSTOM_RANGE_YEAR) {
        let dCurrentStartDate = dayjs(customStartYear);
        let dCurrentEndDate = dayjs(customEndYear);

        currentStartDate = dCurrentStartDate
        currentEndDate = dCurrentEndDate

        let diffYear = currentEndDate.diff(currentStartDate, 'year');
        previousStartDate = currentStartDate.subtract(diffYear, 'year').toISOString();
        previousEndDate = currentEndDate.subtract(diffYear, 'year').toISOString();

        currentStartDate = currentStartDate.toISOString();

        xAxisMode = X_AXIS_MODE_YEAR;
    }

    // limit end date cannot > today end
    if(range != RANGE_FULL_YEAR) {
        currentEndDate = currentEndDate.isAfter(dayjs()) ? dayjs().toISOString() : currentEndDate.toISOString();
    } else {
        currentEndDate = currentEndDate.toISOString();
    }

    return {
        currentStartDate,
        currentEndDate,

        previousStartDate,
        previousEndDate,

        xAxisMode,
    }
}

export const managementFilter = (range, companyCode, customYear, customMonth, customStartYear, customEndYear) => {
    let companyCodes = companyCode.join('|').replace("KEC_ALL", '.*');
 
    return {
        companyCode: companyCodes,
        range,

        ...rangeFilter(range, customYear, customMonth, customStartYear, customEndYear)
    }
} 

export const dateCentreClientFilter = (range, centreId, clientId, customYear, customMonth, customStartYear, customEndYear) => {
    let centreIds = centreId.join('|').replace("KEC_ALL", '.*');
    let clientIds = clientId.join('|').replace("KEC_ALL", '.*');

    return {
        centreId: centreIds,
        clientId: clientIds,
        range,

        ...rangeFilter(range, customYear, customMonth, customStartYear, customEndYear)
    }
}


export const BUSINESS_TYPE_END_TO_END = "End to End";
export const BUSINESS_TYPE_CUS_CLEARNACE_AND_LAST_MILE_DELIVERY = "Import CC + LM";
export const BUSINESS_TYPE_CUS_CLEARNACE = "TODO";
export const BUSINESS_TYPE_LAST_MILE_DELIVERY = "Lastmile Only";

export const BUSINESS_TYPE = [
    { value: BUSINESS_TYPE_END_TO_END, text: "End to End" },
    { value: BUSINESS_TYPE_CUS_CLEARNACE_AND_LAST_MILE_DELIVERY, text: "Customer Clearance & Last-mile Delivery" },
    { value: BUSINESS_TYPE_CUS_CLEARNACE, text: "Customer Clearance" },
    { value: BUSINESS_TYPE_LAST_MILE_DELIVERY, text: "Last-mile Delivery" },
];



export const ORDER_STATUS_ORDER_CREATE = "orderCreatedAt";
export const ORDER_STATUS_INBOUND = "inboundedAt";
export const ORDER_STATUS_ATA = "actualTimeArrivalAt";
export const ORDER_LAST_MILE_RECEIVED = "lastMileHandoverAt";

export const ORDER_STATUS_MAP = {
    [BUSINESS_TYPE_END_TO_END]: [
        { value: ORDER_STATUS_ORDER_CREATE, text: "Order Created" },
        { value: ORDER_STATUS_INBOUND, text: "Inbounded" },
    ],
    [BUSINESS_TYPE_CUS_CLEARNACE_AND_LAST_MILE_DELIVERY]: [
        { value: ORDER_STATUS_ORDER_CREATE, text: "Order Created" },
        { value: ORDER_STATUS_ATA, text: "ATA" },
    ],
    [BUSINESS_TYPE_CUS_CLEARNACE]: [
        { value: ORDER_STATUS_ORDER_CREATE, text: "Order Created" },
        { value: ORDER_STATUS_ATA, text: "ATA" },
    ],
    [BUSINESS_TYPE_LAST_MILE_DELIVERY]: [
        { value: ORDER_STATUS_ORDER_CREATE, text: "Order Created" },
        { value: ORDER_LAST_MILE_RECEIVED, text: "Last-mile Received" },
    ],
}


export const kparcelFilter = (businessType, productId, orderStatus, range, clientId, originalCountry, destinationCountry, customYear, customMonth, customStartYear, customEndYear) => {
    return {
        businessType,
        productId,
        orderStatus,
        clientId,
        originalCountry,
        destinationCountry,

        ...rangeFilter(range, customYear, customMonth, customStartYear, customEndYear)
    }
}