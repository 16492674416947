import Permissions from '@configs/permissions';

export default [
  {
    path: '/sortation/management',
    name: 'sortation-management',
    component: () => import('@/views/sortation/SortationManagement.vue'),
    meta: {
      resource: Permissions.SORTATION,
      pageTitle: 'Management',
      breadcrumb: [
        {
          text: 'Sortation',
        },
        {
          text: 'Management',
          active: true,
        },
      ],
    }
  },
  {
    path: '/sortation/operations',
    name: 'sortation-operations',
    component: () => import('@/views/sortation/SortationOperations.vue'),
    meta: {
      resource: Permissions.SORTATION,
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Sortation',
        },
        {
          text: 'Operations',
          active: true,
        },
      ],
    }
  },
  {
    path: '/sortation/shopee/relabel',
    name: 'sortation-shopee-relabel',
    component: () => import('@/views/sortation/shopee/ShopeeRelabel.vue'),
    meta: {
      resource: Permissions.SORTATION,
      pageTitle: 'Shopee Relabel',
      breadcrumb: [
        {
          text: 'Sortation',
        },
        {
          text: 'Shopee Relabel',
          active: true,
        },
      ],
    }
  },
  {
    path: '/sortation/real-time/productivity-per-hour',
    name: 'sortation-real-time-productivity-per-hour',
    component: () => import('@/views/sortation/real-time/ProductivityPerHour.vue'),
    meta: {
      resource: Permissions.SORTATION,
      pageTitle: 'Productivity Per Hour',
      breadcrumb: [
        {
          text: 'Sortation',
        },
        {
          text: 'Real Time Data',
        },
        {
          text: 'Productivity per Hour',
          active: true,
        },
      ],
    }
  },
]
