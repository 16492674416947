import Permissions from '@configs/permissions';

export default [
  {
    path: '/fulfillment/management',
    name: 'fulfillment-management',
    component: () => import('@/views/fulfillment/FulfillmentManagement.vue'),
    meta: {
      resource: Permissions.FULFILLMENT,
      pageTitle: 'Management',
      breadcrumb: [
        {
          text: 'Fulfillment',
        },
        {
          text: 'Management',
          active: true,
        },
      ],
    }
  },
  {
    path: '/fulfillment/accrual',
    name: 'fulfillment-accrual',
    component: () => import('@/views/fulfillment/FulfillmentAccrual.vue'),
    meta: {
      resource: Permissions.FULFILLMENT,
      pageTitle: 'Accrual',
      breadcrumb: [
        {
          text: 'Fulfillment',
        },
        {
          text: 'Accrual',
          active: true,
        },
      ],
    }
  },
  {
    path: '/fulfillment/operations',
    name: 'fulfillment-operations',
    component: () => import('@/views/fulfillment/FulfillmentOperations.vue'),
    meta: {
      resource: Permissions.FULFILLMENT,
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Fulfillment',
        },
        {
          text: 'Operations',
          active: true,
        },
      ],
    }
  },

  // real time data
  {
    path: '/fulfillment/real-time/outbound-order',
    name: 'fulfillment-real-time-outbound-order',
    component: () => import('@/views/fulfillment/real-time/OutboundOrder.vue'),
    meta: {
      resource: Permissions.FULFILLMENT,
      pageTitle: 'Outbound Order',
      breadcrumb: [
        {
          text: 'Fulfillment',
        },
        {
          text: 'Real Time',
        },
        {
          text: 'Outbound Order',
          active: true,
        },
      ],
    }
  },
]
