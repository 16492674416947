import { RANGE_CURRENT_MONTH, kparcelFilter, RANGE_CUSTOM_RANGE_YEAR, BUSINESS_TYPE_END_TO_END, ORDER_STATUS_MAP, ORDER_STATUS_ORDER_CREATE, RANGE_CUSTOM_YEAR, RANGE_CUSTOM_MONTH } from "@configs/filter";
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import { getZonedCurrentYearStart, getZonedCurrentMonthStart, getZonedCurrentYearEnd } from "@/utils/date-time-helper";
import {
    getFilterPreference,
} from "@/utils/account-localstorage";

export default {
    namespaced: true,
    state: {
        filter: {
            businessType: BUSINESS_TYPE_END_TO_END,
            orderStatus: ORDER_STATUS_ORDER_CREATE,
            productId: null,
            clientId: null,
            range: getFilterPreference().preferKParcelRange, //RANGE_CURRENT_MONTH,
            originalCountry: null,
            destinationCountry: null,

            customYear: null,
            customMonth: null,
            customStartYear: null,
            customEndYear: null,
        },
        fromCountry: {
            isLoading: false,
            isLoadFailed: false,
            list: [],
        },
        toCountry: {
            isLoading: false,
            isLoadFailed: false,
            list: [],
        }
    },
    getters: {
        GET_QUERY_PARAMS(state) {
            return kparcelFilter(
                state.filter.businessType, 
                state.filter.productId, 
                state.filter.orderStatus, 
                state.filter.range, 
                state.filter.clientId, 
                state.filter.originalCountry, 
                state.filter.destinationCountry, 
                state.filter.customYear, 
                state.filter.customMonth, 
                state.filter.customStartYear, 
                state.filter.customEndYear
            );
        },

        GET_FROM_COUNTRY_STATE(state) {
            return {
                ...state.fromCountry,
                list: [
                    { value: null, text: "Overall" },
                    ...state.fromCountry.list.filter(item => item != null).map(fromCountry => { return { value: fromCountry, text: fromCountry } })
                ]
            }
        },
        GET_TO_COUNTRY_STATE(state) {
            return {
                ...state.toCountry,
                list: [
                    { value: null, text: "Overall" },
                    ...state.toCountry.list.filter(item => item != null).map(toCountry => { return { value: toCountry, text: toCountry } })
                ]
            }
        },



        GET_FROM_COUNTRY_STATE_WITHOUT_OVERALL(state) {
            return {
                ...state.fromCountry,
                list: state.fromCountry.list.filter(item => item != null).map(fromCountry => { return { value: fromCountry, text: fromCountry } })
            }
        },
        GET_TO_COUNTRY_STATE_WITHOUT_OVERALL(state) {
            return {
                ...state.toCountry,
                list: state.toCountry.list.filter(item => item != null).map(toCountry => { return { value: toCountry, text: toCountry } })
            }
        },
    },
    mutations: {
        UPDATE_FILTER_BUSINESS_TYPE(state, val) {
            state.filter.businessType = val;
            state.filter.orderStatus = ORDER_STATUS_MAP[val][0].value;
        },
        UPDATE_FILTER_ORDER_STATUS(state, val) {
            state.filter.orderStatus = val;
        },
        UPDATE_FILTER_PRODUCT(state, val) {
            state.filter.productId = val;
        },
        UPDATE_FILTER_CLIENT(state, val) {
            state.filter.clientId = val;
        },
        UPDATE_FILTER_RANGE(state, val) {
            state.filter.range = val;

            if (val == RANGE_CUSTOM_YEAR) {
                state.filter.customYear = getZonedCurrentYearStart().toDate();
            } else if (val == RANGE_CUSTOM_MONTH) {
                state.filter.customMonth = getZonedCurrentMonthStart().toDate();
            } else if (val == RANGE_CUSTOM_RANGE_YEAR) {
                state.filter.customStartYear = getZonedCurrentYearEnd().subtract(4, 'year').toDate();
                state.filter.customEndYear = getZonedCurrentYearEnd().toDate();
            }
        },
        UPDATE_FILTER_ORIGINAL_COUNTRY(state, val) {
            state.filter.originalCountry = val;
        },
        UPDATE_FILTER_DESTINATION_COUNTRY(state, val) {
            state.filter.destinationCountry = val;
        },

        UPDATE_FILTER_CUSTOM_YEAR(state, val) {
            state.filter.customYear = val;
        },
        UPDATE_FILTER_CUSTOM_MONTH(state, val) {
            state.filter.customMonth = val;
        },



        UPDATE_FROM_COUNTRY_STATE(state, val) {
            if (val.isLoading != null) {
                state.fromCountry.isLoading = val.isLoading;
            }

            if (val.isLoadFailed != null) {
                state.fromCountry.isLoadFailed = val.isLoadFailed;
            }

            if (val.list != null) {
                state.fromCountry.list = val.list;
            }
        },
        UPDATE_TO_COUNTRY_STATE(state, val) {
            if (val.isLoading != null) {
                state.toCountry.isLoading = val.isLoading;
            }

            if (val.isLoadFailed != null) {
                state.toCountry.isLoadFailed = val.isLoadFailed;
            }

            if (val.list != null) {
                state.toCountry.list = val.list;
            }
        },

        UPDATE_FILTER_CUSTOM_START_YEAR(state, val) {
            state.filter.customStartYear = val;
        },
        UPDATE_FILTER_CUSTOM_END_YEAR(state, val) {
            state.filter.customEndYear = val;
        },
    },
    actions: {
        async FETCH_FROM_COUNTRY_IF_NEED(context, data) {
            if (context.state.fromCountry.list.length == 0 || context.state.fromCountry.isLoadFailed) {
                try {
                    context.commit("UPDATE_FROM_COUNTRY_STATE", { isLoading: true, isLoadFailed: false });
                    const response = await fetcher(
                        apis.listFromCountry,
                        FetchMethod.GET
                    );

                    context.commit("UPDATE_FROM_COUNTRY_STATE", { isLoading: false, isLoadFailed: false, list: response });

                    if(data.pickFirst && response.length > 0) {
                        context.commit("UPDATE_FILTER_ORIGINAL_COUNTRY", response[0]);
                    }


                } catch (e) {
                    context.commit("UPDATE_FROM_COUNTRY_STATE", { isLoading: false, isLoadFailed: true });
                }
            }
        },
        async FETCH_TO_COUNTRY_IF_NEED(context, data) {
            if (context.state.toCountry.list.length == 0 || context.state.toCountry.isLoadFailed) {
                try {
                    context.commit("UPDATE_TO_COUNTRY_STATE", { isLoading: true, isLoadFailed: false });
                    const response = await fetcher(
                        apis.listToCountry,
                        FetchMethod.GET
                    );

                    context.commit("UPDATE_TO_COUNTRY_STATE", { isLoading: false, isLoadFailed: false, list: response });

                    if(data.pickFirst && response.length > 0) {
                        context.commit("UPDATE_FILTER_DESTINATION_COUNTRY", response[0]);
                    }
                } catch (e) {
                    context.commit("UPDATE_TO_COUNTRY_STATE", { isLoading: false, isLoadFailed: true });
                }
            }
        },
    },
}
