import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full.esm";

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import numeral from "numeral"
import moment from 'moment-timezone'
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { getAccessToken } from "@/utils/account-localstorage";

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ElementUI, { locale });

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(BootstrapVue)
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('vue-slick-carousel/dist/vue-slick-carousel.css')

require('vue-slick-carousel/dist/vue-slick-carousel-theme.css')

Vue.config.productionTip = false

Vue.filter("formatTotalPrice", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatUnitPrice", function (value) {
  return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatQty", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatTimestamp", function (value) {
  return moment.tz(value, 'Asia/Hong_Kong').format('YYYY-MM-DD HH:mm:ss');
});

// Axios defaults
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 3000000;
axios.interceptors.request.use(function (config) {
  const token = getAccessToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

axios.interceptors.response.use(
response => response,
error => {
  if (!error.response) {
    store.commit("app/SHOW_ERROR_DIALOG", {
      isOpen: true,
      title: 'Connection Error',
      message: 'Please refresh this page later and try again.',
    });
  } else if (error.response.status === 500) {
    store.commit("app/SHOW_ERROR_DIALOG", {
      isOpen: true,
      title: 'Unexpected Error ' + error.response.status,
      message: 'Please screenshot this message to developer for troubleshooting.',
      detail: error.response.data ? JSON.stringify(error.response.data, null, 2) : ''
    });

    Sentry.captureException(error);
  }

  return Promise.reject(error);
});


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://577dd5414857d35bb167ddcb86bdd038@o4504294377390080.ingest.sentry.io/4505958685147136",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["backoffice.kec-app.com"],
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: true,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.10,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      /ChunkLoadError$/,
      /Loading chunk/,
      /Loading CSS chunk/,
      /Network Error/,
      /Request aborted/,
      /Unexpected token/,
      /NavigationDuplicated/,
      /Navigation cancelled/,
    ]
  });
}

Vue.prototype.application_version = require('../package.json').version;

new Vue({
  router,
  store,
  i18n,
  // vuetify,
  render: h => h(App),
}).$mount('#app')
