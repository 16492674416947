import Permissions from '@configs/permissions'

export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.AUTH,
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/auth/confirm-email/:code',
        name: 'auth-confirm-email',
        component: () => import('@/views/auth/ConfirmEmail.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
        },
    },
    {
        path: '/auth/reset-password/:code',
        name: 'auth-reset-password',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
        },
    },
    {
        path: '/auth/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.OTHER,
        },
    },
    {
        path: '/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/auth/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: Permissions.AUTH,
        },
    },
]