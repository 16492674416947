import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import auth from './routes/auth'
import sortation from './routes/sortation'
import fulfillment from './routes/fulfillment'
import kParcel from './routes/k-parcel'
import other from './routes/other'
import setting from './routes/setting'
import management from './routes/management'
import jira from './routes/jira'
import crm from './routes/crm'
import erp from './routes/erp'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/' },
    ...auth,
    ...sortation,
    ...fulfillment,
    ...kParcel,
    ...other,
    ...setting,
    ...management,
    ...jira,
    ...crm,
    ...erp,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  console.debug("Route to ", to);

  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if(to.path == '/') {
      return next(getHomeRouteForLoggedInUser())
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next(getHomeRouteForLoggedInUser())
  }

  if(to.name != "auth-login" && isLoggedIn) {
    store.dispatch("app/FETCH_CENTERS_IF_NEED", { routeName: to.name });
    store.dispatch("app/FETCH_CLIENT_IF_NEED", { routeName: to.name });
    store.dispatch("app/FETCH_PRODUCT_IF_NEED", { routeName: to.name });
    store.dispatch("app/FETCH_COMPANY_IF_NEED", { routeName: to.name });
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
