import dayjs from 'dayjs';
import { initialAbility } from '@/libs/acl/config';
import store from '@/store'

const ACCESS_TOKEN = "ACCESS_TOKEN";
const ACCESS_TOKEN_EXPIRE_DATE = "ACCESS_TOKEN_EXPIRE_DATE";
const PROFILE = "PROFILE";
const CRM_PROFILE = "CRM_PROFILE";
const ERP_PROFILE = "ERP_PROFILE";

export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
}

export const getAccessTokenExpireDate = () => {
    const expireDate = localStorage.getItem(ACCESS_TOKEN_EXPIRE_DATE);
    if (expireDate) {
        return dayjs(expireDate);
    } else {
        return null;
    }
}

export const setAccessToken = (token, expireDate) => {
    localStorage.setItem(ACCESS_TOKEN, token);
    localStorage.setItem(ACCESS_TOKEN_EXPIRE_DATE, expireDate);
}

export const cleanAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRE_DATE);
    localStorage.removeItem(PROFILE);
}

export const getProfile = () => {
    const profile = localStorage.getItem(PROFILE);
    if (profile) {
        return JSON.parse(profile);
    } else {
        return null;
    }
}

export const setProfile = (profile) => {
    localStorage.setItem(PROFILE, JSON.stringify(profile));

    if(profile != null && profile.filterPreference != null) {
        store.commit("fulfillment/UPDATE_FILTER_RANGE", profile.filterPreference.preferFulfillmentRange);
        store.commit("management/UPDATE_FILTER_RANGE", profile.filterPreference.preferManagementRange);
        store.commit("kParcel/UPDATE_FILTER_RANGE", profile.filterPreference.preferKParcelRange);
    }
}

export const getCrmProfile = () => {
    const profile = localStorage.getItem(CRM_PROFILE);
    if (profile) {
        return JSON.parse(profile);
    } else {
        return null;
    }
}

export const getErpProfile = () => {
    const profile = localStorage.getItem(ERP_PROFILE);
    if (profile) {
        return JSON.parse(profile);
    } else {
        return null;
    }
}

export const getErpCan = (permissionCode) => {
    return getErpProfile().permissionCodes.includes(permissionCode);
}

export const setCrmProfile = (profile) => {
    localStorage.setItem(CRM_PROFILE, JSON.stringify(profile));
}

export const setErpProfile = (profile) => {
    localStorage.setItem(ERP_PROFILE, JSON.stringify(profile));
}

export const getFilterPreference = () => {
    const profile = getProfile();
    if (profile && profile.filterPreference) {
        return profile.filterPreference;
    } else {
        // return default if not exist any setting
        return {
            preferManagementRange: "year_to_date",
            preferFulfillmentRange: "current_month",
            preferKParcelRange: "current_month"
        }
    }
}

export const setFilterPreference = (filterPreference) => {
    const profile = getProfile();
    if (profile) {
        profile.filterPreference = filterPreference;
        setProfile(profile);

        store.commit("fulfillment/UPDATE_FILTER_RANGE", filterPreference.preferFulfillmentRange);
        store.commit("management/UPDATE_FILTER_RANGE", filterPreference.preferManagementRange);
        store.commit("kParcel/UPDATE_FILTER_RANGE", filterPreference.preferKParcelRange);
    }
}

export const getPermissions = () => {
    const profile = getProfile();
    if (profile) {
        return [...profile.permissions.map(permission => ({ action: 'manage', subject: permission.name })), ...initialAbility];
    } else {
        return null;
    }
}
