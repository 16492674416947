import { SORTATION_CHART } from "@/configs/charts";

export default {
    namespaced: true,
    state: {
        tool: {
            numOfChartsPerRow: 2,
            displayCharts: SORTATION_CHART,
        }
    },
    getters: {},
    mutations: {
        UPDATE_NUM_OF_CHARTS_PER_ROW(state, val) {
            state.tool.numOfChartsPerRow = val;
        },
        UPDATE_DISPLAY_CHARTS(state, val) {
            state.tool.displayCharts = val;
        }
    },
    actions: {

    },
}
